import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio.jsx"
import LayoutPage from "../components/layout-page.jsx"
import SEO from "../components/seo.jsx"

const NotFoundPage = ({ data, location }) => {
	const pageTitle = "404 // Page Not Found"

	return (
		<LayoutPage location={location} title={pageTitle}>

			<SEO
				title={pageTitle}
				description="Sorry but the page you are looking for is not here. This is what we call in the biz, a 404."
			/>

			<article className="post__container">

				<header>

					<h1>404 // Page Not Found</h1>

				</header>

				<section>

					<p>There is nothing at this address. It's probably my fault, not yours, but I'm still mostly a good person.</p>

					<p>In all honesty, I likely screwed up something in the migration of the previous version of this site to <a href="https://stories.jodybailey.ca" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">https://stories.jodybailey.ca</a>. I am rushing through this and cutting corners. Judge me how you see fit.</p>

					<p>Or read some published pieces that I've written and/or provided photography for. Through extensive travel the past few years, I've been able to embed myself in the running world, and this is but a sample of my work.</p>

					<h2>Published Work</h2>

					<ul>
						<li><a href="https://www.runnersworld.com/runners-stories/a31980994/afraid-to-run-during-coronavirus/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">Why I’m Afraid to Run During Coronavirus</a> | Runner's World</li>
						<li><a href="https://stories.jodybailey.ca/faces-of-the-2020-u-s-olympic-team-trials-marathon/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">Faces of the 2020 U.S. Olympic Team Trials – Marathon</a> | JodyBailey.ca</li>
						<li><a href="https://religion.run/team-bromka-marathon-story/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">Team Bromka — A Marathon Story</a> | Religion of Run</li>
						<li><a href="https://layover.run/sea-tac-50-miler/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">Sea-Tac 50 Miler</a> | Layover.Run</li>
						<li><a href="https://tempojournal.com/article/2-hours-and-45-minutes/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">2 Hours and 45 Minutes: Chasing An Olympic Trials Qualifier at CIM</a> | TEMPO</li>
						<li><a href="https://tempojournal.com/article/the-symbiotic-partnership-of-allie-kieffer-and-coach-hudson/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">The Symbiotic Partnership of Allie Kieffer & Coach Hudson</a> | TEMPO</li>
						<li><a href="https://www.innervoice.life/dylan-wykes" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">I'll Always Be A Runner</a> | InnerVoice</li>
						<li><a href="https://tempojournal.com/article/2018-bmo-vancouver-marathon/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">2018 BMO Vancouver Marathon: A Rob Watson Story</a> | TEMPO</li>
						<li><a href="https://cieleathletics.com/tsp4-0-the-exchange/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">TSP 4.0 — The Exchange</a> | Ciele Athletics</li>
					<li><a href="https://www.likethewindmagazine.com/2020/03/25/setting-the-stage/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">Setting The Stage</a> | Like the Wind Magazine</li>
					</ul>

				</section>

				<footer>

					<Bio />

				</footer>

			</article>

		</LayoutPage>
	)
}

export default NotFoundPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
