import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio.jsx"
import LayoutPage from "../components/layout-page.jsx"
import SEO from "../components/seo.jsx"

const privacyPolicyPage = ({ data, location }) => {
	const pageTitle = "Privacy Policy"

	return (
		<LayoutPage location={location} title={pageTitle}>

			<SEO
				title={pageTitle}
				description="Apparently it is 2020 and every website no matter how small or ridiculous, still needs to have a privacy policy."
			/>

			<article className="post__container">

				<header>

					<h1>Privacy Policy</h1>

				</header>

				<section>

					<h2>Who I is</h2>
					<p>This website address is: <Link to={`/`}>https://jodybailey.ca</Link>.</p>

					<h2>What personal data I collect and why I collect it</h2>
					<h3>Comments</h3>
					<p>You don't see any comments here, but if you do, don't read them.</p>

					<h3>Media</h3>
					<p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website. Also, if you upload images to the site...umm, how?</p>

					<h3>Contact Forms</h3>
					<p>If I ever have a contact form, which I won't, you will be submitting data in that form. I know, wild? Understand that whatever you submit, I will be using to enhance your experience on the site.</p>

					<h3>Cookies</h3>
					<p>No, not those cookies.</p>
					<p>I set one cookie. Yeah, I am heavy with the data. It's used for caching...I think. Talk to Gatsby, I haven't gotten there yet with this platform.</p>

					<h3>Embedded Content From Other Sites</h3>
					<p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>

					<p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

					<h3>Analytics</h3>
					<p>No analytics here. Perhaps coming through Cloudflare counts? Hmmm, I might have to look into that.</p>

					<h3>Who I Share Your Data With</h3>
					<p>Yeah, no one. I willingly share your data with absolutely no other vendors, parties, organizations, or people. Okay, so my dad. I tell my dad how many people read my work. Sue me.</p>

					<h2>How long I retain your data</h2>
					<p>For users that register on this website (which you can't do), I also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (which there are none). Website administrators can also see and edit that information (I can't). Note, this is a flat file CMS with no users ... so ... yeah.</p>

					<h2>What Rights You Have Over Your Data</h2>
					<p>If you have an account on this site (see above), or have left comments (see above above), you can request to receive an exported file of the personal data I hold about you, including any data you have provided to me. You can also request that I erase any personal data I hold about you. This does not include any data I am obliged to keep for administrative, legal, or security purposes.</p>

					<h2>Where I Send Your Data</h2>
					<p>I think it's clear at this point, I don't really keep any data, so I don't send any data anywhere.</p>

					<h2>Caching</h2>
					<p>This site utilizes caching in order to facilitate a faster response time and better user experience. Caching potentially stores a duplicate copy of every web page that is on display on this site. All cache files are temporary, and are never accessed by any third party, except as necessary to obtain technical support from the cache plugin vendor. Cache files expire on a schedule set by the site administrator, but may easily be purged by the admin before their natural expiration, if necessary.</p>

				</section>

				<footer>

					<Bio />

				</footer>

			</article>

		</LayoutPage>
	)
}

export default privacyPolicyPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
