import React from "react"
import { Link, graphql } from "gatsby"

import LayoutHeader from "../components/layout-header.jsx"
import LayoutFooter from "../components/layout-footer.jsx"
import Bio from "../components/bio.jsx"
import SEO from "../components/seo.jsx"

const BlogPostTemplate = ({ data, pageContext, location }) => {
	const post = data.markdownRemark
	const { previous, next } = pageContext

	return (
		<div className="main__container">

			<SEO
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
			/>

			<header><LayoutHeader /></header>

			<main>

				<article className="post__container">

					<header>

						<h1>{post.frontmatter.title}</h1>

						<p><small>{post.frontmatter.date}</small> / <small>{post.fields.readingTime.text}</small></p>

					</header>

					<section dangerouslySetInnerHTML={{ __html: post.html }} />

					<footer>

						<Bio />

					</footer>

				</article>

				<nav className="post__nav">
					<ul>
						<li>
							{previous && (
								<Link to={previous.fields.slug} rel="prev">
									← {previous.frontmatter.title}
								</Link>
							)}
						</li>
						<li>
							{next && (
								<Link to={next.fields.slug} rel="next">
									{next.frontmatter.title} →
								</Link>
							)}
						</li>
					</ul>
				</nav>

			</main>

			<footer><LayoutFooter /></footer>

		</div>
	)
}

export default BlogPostTemplate

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			excerpt(pruneLength: 160)
			html
			fields {
				readingTime {
					text
				}
			}
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				description
			}
		}
	}
`
