// Gatsby supports TypeScript natively!
import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import LayoutPage from "../components/layout-page.jsx"
import SEO from "../components/seo.jsx"

const Home = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata.title
	const posts = data.allMarkdownRemark.edges

	return (
		<LayoutPage location={location} title={siteTitle}>

			<SEO
				title="I used to be a traveling photo journalist"
				description={data.site.siteMetadata.description}
			/>

			<h1 className="home_cta">{data.site.siteMetadata.description}</h1>

			<Image className="home_hero" fluid={data.jodyHeroImg.childImageSharp.fluid} />

			<section className="post_listing__container">

				{posts.map(({ node }) => {
					const title = node.frontmatter.title || node.fields.slug
					return (

						<article className="post__container" key={node.fields.slug}>

							<header>

								<h2>
									<Link to={node.fields.slug}>
										{title}
									</Link>
								</h2>

								<p><small>{node.frontmatter.date}</small> / <small>{node.fields.readingTime.text}</small></p>

							</header>

							<section>
								<p dangerouslySetInnerHTML={{
									__html: node.frontmatter.description || node.excerpt,
								}} />
							</section>

						</article>

					)
				})}

			</section>

		</LayoutPage>
	)
}

export default Home

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				description
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			edges {
				node {
					excerpt
					fields {
						slug
						readingTime {
							text
						}
					}
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						title
						description
					}
				}
			}
		}
		jodyHeroImg: file(relativePath: { eq: "IMG_0001_1_full.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1400) {
					aspectRatio
					srcSetWebp
				}
			}
		}
	}
`
