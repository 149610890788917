/**
 * Template Footer component
 *
 */

import React from "react"
import { Link } from "gatsby"

const TemplateFooter = () => {
	return (
		<div className="footer__container">

			<Link className="jb_logo" to={`/`}>

				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215 106" aria-labelledby="jbLogo" role="img"><title id="jbLogo">Jody Bailey's logo, three overlapping triangles of different colours.</title><path class="rear_t" d="M117.475.12c-.385-.208-.866-.14-1.18.17l-105 104.003c-.287.286-.374.716-.22 1.09.155.373.52.617.925.617h83.508c.475 0 .885-.332.98-.796L117.98 1.198c.088-.43-.116-.87-.505-1.08z"/><path class="middle_t" d="M144.55 104.18L55.597 46.164c-.41-.268-.954-.2-1.286.154L.265 104.335c-.27.29-.34.71-.18 1.07.158.36.52.595.916.595H144c.44 0 .832-.288.96-.707.128-.42-.038-.872-.41-1.113z"/><path class="letters" d="M140 65.45v-9.38h26v31.92c0 4.833-.46 9.5-4.195 13.09-3.734 3.59-6.818 4.92-11.532 4.92-14.536 0-11.344-.143-16.546-5.51l6.763-9.7c2.94 3.173 6.02 4.76 9.24 4.76 1.54 0 2.858-.595 3.955-1.785s1.645-2.975 1.645-5.355V65.45H140zM197.333 106H176V56.07h18.98c3.313 0 6.172.397 8.575 1.19 2.403.793 4.212 1.867 5.425 3.22 2.193 2.52 3.29 5.367 3.29 8.54 0 3.827-1.237 6.673-3.71 8.54-.84.653-1.423 1.062-1.75 1.225-.327.163-.91.432-1.75.805 3.033.653 5.448 2.018 7.245 4.095C214.102 85.762 215 88.34 215 91.42c0 3.407-1.167 6.417-3.5 9.03-2.707 3.033-7.447 5.55-14.167 5.55zM186.86 75.88h5.18c3.033 0 5.285-.327 6.755-.98 1.47-.653 2.205-2.065 2.205-4.235 0-2.17-.677-3.605-2.03-4.305-1.353-.7-3.64-1.05-6.86-1.05h-5.25v10.57zm0 19.88h7.49c3.127 0 5.472-.385 7.035-1.155 1.563-.77 2.345-2.287 2.345-4.55s-.828-3.757-2.485-4.48c-1.657-.723-4.305-1.085-7.945-1.085h-6.44v11.27z"/><path class="front_t" d="M144.734 104.335L90.687 46.32c-.333-.357-.878-.424-1.285-.156L.452 104.18c-.37.24-.537.693-.41 1.113.127.42.517.707.96.707H144c.398 0 .757-.233.916-.594.16-.36.087-.782-.182-1.07z"/></svg>

			</Link>

			<p>
				<small>I have a <Link to={`/privacy-policy/`}>Privacy Policy</Link> and <Link to={`/terms-conditions/`}>Terms & Conditions</Link> but consider this site, my second Gatsby build, just another insignificant part of the noodle soup that is my career. See <a href="https://jodybailey.ca/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">JodyBailey.ca</a>, <a href="https://3am.Coffee/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">3am.Coffee</a>, and <a href="https://3oh6.com/" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">3oh6.com</a>.</small>

				<span>&#9400; {new Date().getFullYear()} Noodle Soup Inc</span>

			</p>

		</div>
	)
}

export default TemplateFooter
