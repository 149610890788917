/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
	const data = useStaticQuery(graphql`
		query BioQuery {
			avatar: file(relativePath: { eq: "unitB_headshot-12-2012.jpg" }) {
				childImageSharp {
					fixed(width: 75, height: 75) {
						...GatsbyImageSharpFixed
					}
				}
			}
			site {
				siteMetadata {
					author {
						name
						summary
					}
					social {
						twitter
					}
				}
			}
		}
	`)

	const { author, social } = data.site.siteMetadata
	return (
		<section className="bio__container">
			<Image
				fixed={data.avatar.childImageSharp.fixed}
				alt={author.name}
				imgStyle={{
					borderRadius: `50%`,
				}}
			/>
			<p>
				<strong>{author.name}</strong> {author.summary}
				<br />
				<a href={social.twitter}>I'm on the Tweetsauce.</a>
			</p>
		</section>
	)
}

export default Bio
