import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio.jsx"
import LayoutPage from "../components/layout-page.jsx"
import SEO from "../components/seo.jsx"

const privacyPolicyPage = ({ data, location }) => {
	const pageTitle = "About"

	return (
		<LayoutPage location={location} title={pageTitle}>

			<SEO
				title={pageTitle}
				description="Jody Bailey used to be a traveling photo journalist and storyteller. Now, well, I am trying to build the internet again. Exploring Gatsby with this site."
			/>

			<article className="post__container">

				<header>

					<h1><span role="img" aria-label="donut emoji">🍩</span> About Jody Bailey <span role="img" aria-label="crown emoji">👑</span></h1>

				</header>

				<section>

					<p>This site is an exploration into my privilege, and the system of white supremecy that has provided this privilege to me for over forty years.</p>

					<p>I visually identify as male, raised in a stable home above a safety net of white privilege, and the sole beneficary of a lifetime of nepetism.</p>

					<h2>I would argue, the trifecta of white privilege.</h2>

					<p>Has my life been easy, no. I do not believe anyone has an <em>easy</em> life. Because easy and hard are relative.</p>

					<p><em>But has my life been void of hurdles other people face?</em> <strong>Absolutely</strong>.<br/>When I fuck up, is my white safety net always there? <strong>You bet your ass.</strong><br/ ><em>Has the return on my effort always been 1:1 or higher?</em> <strong>Without fail.</strong></p>

					<h3>This is white privilege</h3>

					<p>My goals are to speak about my privilege through the only lense I know, that of a white male. I honestly just assume that is my primary audience as well given my involvement in the running and web development communities. Both very white, and both very male dominated, communities.</p>

					<p>I will make mistakes. I will learn from them. But I've got a lifetime of that behind me, so none of this will be new. Please understand  my only goal in these words I share, is to help identify white supremacy. Help others identify the systems in place that support white supremacy, and how those systems aren't equal for everyone.</p>

					<h2>As a graduate of the <a href="https://www.fims.uwo.ca/programs/undergraduate_programs/media_information_technoculture/">Media Information & Technoculture</a> program at Western University, I see a world heavily influenced by the media we consume, and  technologies used in consumption.</h2>

					<p>That means, I apporoach topics with a few pretenses.</p>

					<p><em>Men are the worst</em>.<br />White men even moreso.</p>

					<p>I use the term <em>media</em> to describe all that which we consume. News, social media, literature, movies, comedy, music...et el. And yes, the media we consume, <em>I feel</em> plays an influential role in our lives. To be crystal clear, I view media as an influencer of, and influenced by, culture. Equally, so does the environment we live in.</p>

					<p>I firmly believe all of our social issues are intertwined and all need to be addressed for a truly equal and just society. Capitalism is at the root of everything, and white supremacy its primary tool. The bullshit I've been fed in my four decades has resulted in nothing but growing inequality. It's obvious this system is <strong>fucked</strong>.</p>

					<p>We, every single privileged white person, needs to critically examine and address the various systems in place designed to oppress the minority, and support the majority.</p>

					<p>BIPOC (Black, Indigenous, and People of Colour) are targeted by bureaucratic systems, segregated by infrastructure in plain sight, and continue to be murdered and terrorized by the police while protesting being murdered and terrorized by the police.</p>

					<p>Mind boggling.</p>

					<blockquote><p><a href="https://www.cnbc.com/2020/05/21/american-billionaires-got-434-billion-richer-during-the-pandemic.html" target="_blank" rel="noreferrer" aria-label="This is an external link (opens in a new tab)">Billionaires are making billions</a> during a global pandemic whose weight is being shouldered by an <em>essential</em> working class already shouldering the burden of increasing inequality, and hardest hit by the virus itself.</p></blockquote>

					<p>Again, mind boggling.</p>

					<p>The icing on this cluster fuck of a cake is where I simply can't get past.</p>

					<p>A reality television actor, underachieving trust fund baby, and business loser; recorded saying you "can just grab them by the pussy" when referring to Women; was <strong>THEN</strong> elected president of The United States. Forget everything up to and after that moment. I simply can't get past a country listening to a man say those words, <strong>and then</strong> elect him <em>president</em>. It is at this precise point that I knew I wasn't even watching the right movie.</p>

					<h3>Capitalism is indeed, a hell of a drug.</h3>

					<p>This is who I am. This is where I come from. I saw the cracks in the Capitalist system at the age of 12 when I called my parents bluff and stopped celebrating Christmas. I have struggled with how to do my part ever since. I guess this is more of the same, but hopefully it helps someone else recognize their privilege.</p>

				</section>

				<footer>

					<Bio />

				</footer>

			</article>

		</LayoutPage>
	)
}

export default privacyPolicyPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
