import React from "react"

import LayoutHeader from "../components/layout-header.jsx"
import LayoutFooter from "../components/layout-footer.jsx"

const Layout = ({ location, title, children }) => {
	return (
		<div className="main__container">

			<header><LayoutHeader /></header>

			<main>

				{children}

			</main>

			<footer><LayoutFooter /></footer>

		</div>
	)
}

export default Layout
