import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio.jsx"
import LayoutPage from "../components/layout-page.jsx"
import SEO from "../components/seo.jsx"

const termsConditionsPage = ({ data, location }) => {
	const pageTitle = "Terms & Conditions"

	return (
		<LayoutPage location={location} title={pageTitle}>

			<SEO
				title={pageTitle}
				description="Terms & Conditions are another one of these things any and all websites are suppost to have. So, yeah."
			/>

			<article className="post__container">

				<header>

					<h1>Terms & Conditions</h1>

				</header>

				<section>

					<h2>These are my terms</h2>
					<p>You came here, therefore you assume all risk and responsibility of consuming the thoughts inside my mind.</p>

				</section>

				<footer>

					<Bio />

				</footer>

			</article>

		</LayoutPage>
	)
}

export default termsConditionsPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
